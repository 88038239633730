#book-note-taker-app {
  display: block;
  margin: auto;
  max-width: 85%;
}

.danger {
  color: rgba(148, 28, 28, 0.66);
}

#json-display {
  width: 100%;
  margin: auto;
  display: block;
}

#json-display.invalid {
  background-color: rgb(255, 230, 230);
}

#json-display.valid {
  background-color: rgb(230, 250, 230);
}

.invalid {
  opacity: 0.25;
}

header ul {
  margin-left: 0;
  padding-left: 0;
}

header ul li {
  font-size: 0.8em;
  display: inline;
  margin: 0 0.5em;
  padding: 0.2em 0.5em;
}

#button-controls button {
  border: solid 1px #aaa;
  font-size: 0.8em;
}

@media only screen and (min-width: 600px) {
  .entry-field, 
  #json-zone,
  #button-controls
  {
    margin-left: 0.5em;
  }

  #button-controls button {
    font-size: 1em;
    margin: 0 0.5em;
  }

  .entry-field input {
    margin-left: 0.5em;
    width: 20em;
    font-size: 1em;
  }

  #json-zone {
    border:solid 1px #888;
  }

  #json-zone #render-header {
    font-size: 0.8em;
    margin: 0.5em;
  }

  #json-zone #render-header p {
    margin: 0.5em
  }


  #json-display {
    font-size: 1.2em;
    font-family: monospace, sans-serif;
  }

  #button-controls {
    margin-bottom: 0.5em;
  }
}

@media only screen and (max-width: 600px) {
  #app-header,
  .entry-field,
  #json-display,
  #book-note-taker-app p
  {
    font-size: 0.25em;
    margin: 0.1em 0.3em;
  }

  .entry-field input {
    font-size: 0.5em;
  }

  #button-controls button {
    font-size: 6pt;
    margin: 0 0.1em;
  }
}
